import Vue from 'vue'
import Router from 'vue-router'
import Index from '../views/Index.vue'
import Content from '../views/Content.vue'
import Login from '../views/Login.vue'
import Member from '../views/Member.vue'
import NotFound from '../views/NotFound.vue'
Vue.use(Router)

const router = new Router({
	mode: 'hash',
	// base: process.env.BASE_URL,
	routes: [
		//默认跳转首页
		{
			path: '',
			redirect: '/Login'
		},
		{
			path: '/login',
			component: Login
		},

		{
			path: '/',
			component: Index,
			children: [{
					path: '/content',
					name: 'content',
					component: Content
				},

				{
					path: '/member',
					name: 'member',
					component: Member
				},
				{
					path: '/404',
					component: NotFound
				}
			]
		},
	]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
	const LOGINURL = '/login'
	let url, user
	url = to.path
	user = sessionStorage.getItem('user')
	if (url === LOGINURL) {
		sessionStorage.removeItem('user')
	}
	// 判断是否登录
	if (!user && url !== LOGINURL) {
		next({
			path: LOGINURL
		})
	} else {
		next()
	}
})

export default router
