import axios from 'axios';
import qs from 'qs';
import router from '../router'
import VueCookies from 'vue-cookies'
import {
	Message
} from 'element-ui';

axios.defaults.timeout = 8000;
// axios.defaults.baseURL = 'https://api.lmcro.com/v1'
axios.defaults.withCredentials = false;
axios.defaults.retry = 2;

//http response 拦截器
axios.interceptors.response.use(
	response => {
		if (response.data.code == "7001") {
			router.push({
				path: "/login",
				querry: {
					redirect: router.currentRoute.fullPath
				} //从哪个页面跳转
			})
		}
		return response;
	},
	(error) => {
		return Promise.reject(error)
	}
)


axios.interceptors.request.use(config => {
	let token = VueCookies.get('mb_token');
	console.log("token" + token);
	console.log("config.type__" + config.type);
	console.log("data--" + config.data);
	if (token) {
		 config.headers.common["token"] = token;
	}
	if (config.type == 'formData' || config.method != 'post') {
		return config
	}
	if(config.data["isForMultiPart"]) {  //用于专门处理项目
		console.log("--isForMultiPart--");
		return config
	}
	config.data = qs.stringify(config.data)
	return config
}, (error) => {
	return Promise.reject(error);
})

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params
			})
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				reject(err)
			})
	})
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.patch(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.put(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

/**
 * 封装delete请求
 * @param url
 * @returns {Promise}
 */

export function delet(url) {
	return new Promise((resolve, reject) => {
		axios.delete(url)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

