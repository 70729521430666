<template>
  <div class="not-found">
    Sorry, Not Found the Page :)
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.not-found {
  font-size: 30px;
  letter-spacing: 5px;
  text-align: center;
  margin-top: 250px;
}
</style>
