<template>
	<div class="login-box">
		<el-form :model="loginForm" :rules="rules" ref="loginForm" class="login-form">
			<h3 class="title">亦知临床后台系统登录</h3>
			<el-form-item prop="loginname">
				<el-input v-model="loginForm.loginname" autocomplete="off" placeholder="请输入账号" prefix-icon="el-icon-goods">
				</el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input :type="textType" v-model="loginForm.password" @keyup.native.enter="login('loginForm')" autocomplete="off"
				 placeholder="请输入密码" prefix-icon="el-icon-time">
					<i slot="suffix" class="el-input__icon el-icon-view btn-eye" @click="changeType"></i>
				</el-input>
			</el-form-item>
			<el-form-item style="margin-bottom:0;">
				<el-checkbox v-model="checked" style="color:#a0a0a0;">记住密码</el-checkbox>
			</el-form-item>
			<el-form-item v-show="showMsg" style="margin-bottom:0;">
				<span class="text-danger">提示：用户名或密码错误，请重试！</span>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="login('loginForm')" class="login-btn" v-loading="loading">登 录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loginForm: {
					loginname: '',
					password: ''
				},
				rules: {
					loginname: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				loading: false,
				showMsg: false,
				textType: 'password',
				checked: true,
				sysValue: 0,
				system: 0,
			}
		},
		mounted() {
			this.getCookie();
		},
		methods: {
			login(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true
						console.log("sysValue>" + this.sysValue)
						// this.$axios.defaults.baseURL = 'https://apimp.mbzhaomu.cn/v1'
						this.$axios.defaults.baseURL = 'https://api.lmcro.com/v1'
						sessionStorage.setItem('baseURL', this.$axios.defaults.baseURL);
						this.$cookies.set('baseURL', this.$axios.defaults.baseURL);
						this.$post('/member/login', this.loginForm).then((res) => {
							// 延迟两秒，演示登录按钮加载效果
							if (res.state == 1 && res.code == "0000") {
								console.log("mb_token-->" + res.data)
								setTimeout(() => {
									if (this.checked == true) {
										//传入账号名，密码，和保存天数3个参数
										this.setCookie(this.loginForm.loginname, this.loginForm.password, 7);
									} else {
										console.log("清空Cookie");
										//清空Cookie
										this.clearCookie();
									}
									this.loading = false;
									this.$cookies.set('mb_token', res.data.token);
									sessionStorage.setItem('user', JSON.stringify(this.loginForm));
									console.log("login_" + localStorage.getItem('baseURL'));
									this.$message({
										message: '登录成功！',
										type: 'success'
									});
									sessionStorage.setItem('member', JSON.stringify(res.data));
									
									this.$router.replace({
										path: '/content'
									})
								}, 300)
							} else {
								this.loading = false;
								this.showMsg = true;
							}
						})
					} else {
						console.log('login failed')
						return false
					}
				})
			},
			changeType() {
				this.textType = (this.textType === 'password' ? 'text' : 'password')
			},
			//设置cookie
			setCookie(c_name, c_pwd, exdays) {
				var exdate = new Date(); //获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
				//字符串拼接cookie
				window.document.cookie = "loginname" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
				window.document.cookie = "password" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
			},
			//读取cookie
			getCookie: function() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split('='); //再次切割
						//判断查找相对应的值
						if (arr2[0] == 'loginname') {
							//  console.log(arr2[1])
							this.loginForm.loginname = arr2[1]; //保存到保存数据的地方
						} else if (arr2[0] == 'password') {
							// console.log(arr2[1])
							this.loginForm.password = arr2[1];
						}
					}
					this.checked = true;
				}
			},
			//清除cookie
			clearCookie: function() {
				this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
			}


		}
	}
</script>

<style lang="scss" scoped>
	.login-box {
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: #20222A;

		.login-form {
			border-radius: 5px;
			background-clip: padding-box;
			margin: 180px auto;
			width: 350px;
			padding: 35px 35px 15px 35px;
			background: #f5f5f5;
			border: 1px solid #eaeaea;
			box-shadow: 0 0 5px #cac6c6;

			.title {
				margin: 0px auto 40px auto;
				text-align: center;
				color: #505458;
			}

			.btn-eye {
				cursor: pointer;
			}

			.text-danger {
				color: #F56C6C;
			}

			.login-btn {
				margin: 35px 0 10px 0;
				width: 100%;
			}
		}
	}
</style>
