<template>
	<el-menu class="menu-nav" router :default-active="currentPath" background-color="#20222A" text-color="rgba(255,255,255,.8)"
	 active-text-color="#ffd04b" :collapse="isCollapse">
<!-- 		<el-menu-item index="/project">
			<i class="el-icon-document"></i>
			<span slot="title">项目列表</span>
		</el-menu-item> -->
	
		<el-menu-item index="/content">
			<i class="el-icon-coin"></i>
			<span slot="title">内容管理</span>
		</el-menu-item>

		
		<el-menu-item index="/member" v-if="isManager == 0">
			<i class="el-icon-date"></i>
			<span slot="title">用户列表</span>
		</el-menu-item>
		

	</el-menu>
</template>

<script>
	export default {
		props: ['isCollapse'],
		data() {
			return {
				currentPath: location.pathname,
				isManager:1,
			}
		},
		mounted() {
			this.isManager = JSON.parse(sessionStorage.getItem('member')).type;
		},
	}
</script>

<style lang="scss" scoped>
	.el-menu {
		border: none;
	}

	.menu-nav:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
</style>
