import Vue from 'vue'
import axios from 'axios'
import {post,fetch,patch,put,delet} from './common/http'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Utils from './common/utils'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
 Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import TreeTable from 'vue-table-with-tree-grid'



Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MintUI)
Vue.component('tree-table', TreeTable)

//定义全局变量
Vue.prototype.$post=post;
Vue.prototype.$fetch=fetch;
Vue.prototype.$patch=patch;
Vue.prototype.$put=put;
Vue.prototype.$delet=delet;

Vue.prototype.Utils = Utils

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
