<template>
	<div class="article-box">
		<el-row>
			<el-col :span="24">
				<div class="tool-box">
					<el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新增内容</el-button>
				</div>
			</el-col>
		</el-row>
		<el-table :data="tableData" v-loading="loading" style="width:100%;margin-top: 20px;" >
			<el-table-column prop="id" label="ID" width="80">
			</el-table-column>
			<el-table-column prop="title" label="标题" width="200">
			</el-table-column>
 			<el-table-column label="类别" width="100">
				<template slot-scope="scope">
					{{['临床知识', '临床法规', '动态新闻','话术案例','平台操作','项目培训'][scope.row.type]}}
				</template>
			</el-table-column>
			<el-table-column label="置顶状态" width="100">
				<template slot-scope="scope">
					{{['已置顶','未置顶'][scope.row.istop]}}
				</template>
			</el-table-column>
			<el-table-column prop="content" label="内容" :show-overflow-tooltip = "true" >
			</el-table-column>
			<el-table-column label="操作" width="300">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" plain @click="handleEdit(scope.$index, scope.row)">编辑</el-button>

					<el-button size="mini" type="primary" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						
				</template>
				
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
		 :page-sizes="[5, 10, 20, 40]" :page-size="pagesize" layout="total, sizes,prev, pager, next" :total="num" prev-text="上一页"
		 next-text="下一页">
		</el-pagination>
		<el-dialog :title="dialogTitle" :visible.sync="articleFormVisible" :close-on-click-modal=false top="10vh" @close="resetForm('articleForm')">
			<el-form :model="article" ref="articleForm" :rules="rules">
				<el-form-item label="内容标题" prop="title" label-width="100px">
					<el-input v-model="article.title" placeholder="请输入内容" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="图片" prop="sharePic" label-width="100px">
					<input type="file" ref="sharfile" accept="image/*" @change="getFile($event)"></input>
				</el-form-item>
				<el-form-item label="内容文本" label-width="100px">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5}"  size="medium" placeholder="请输入内容" v-model="article.content">
					</el-input>
				</el-form-item>
				<el-form-item>
					<quill-editor ref="TextEditor" v-model="article.rtContent" :options="editorOption">
					</quill-editor>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: 1.25rem;">
				<el-button @click="articleFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="editArticle('articleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 引入富文本编辑器所需样式
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor
	} from 'vue-quill-editor'
	import quillConfig from '../common/quill-config.js'
	import qs from 'qs'
	import axios from 'axios' //引入
	import VueCookies from 'vue-cookies'
	export default {
		data() {
			return {
				dialogTitle: '编辑内容 ',
				currentPage: 1, //默认显示页面为1
				pagesize: 10, //    每页的数据条数
				articleFormVisible: false,
				rowIndex: 9999,
				tableData:[],
				loading: true,
				isAddArticle: false,
				sharePic: '',
				file:'',
				mpName:'',
				memberId:'',
				mpOptions:[],
				article: {
					id: '',
					title: '',
					memberId:'',
					type: 0,
					content: '',
					rtContent: '',
					istop:'1',
					isForMultiPart: false
				},
				num: 100,
				rules: {
						title: [
							{ required: true, message: '请输入文章名称', trigger: 'blur' }
						],
						type: [
							{ required: true, message: '请选择文章类型', trigger: 'blur' }
						],
						content: [
							{ required: true, message: '请输入文章摘要', trigger: 'blur' }
						]
						
				},
				editorOption: quillConfig,
				articleBackup: Object.assign({}, this.article),
			}
		},
		mounted() {
			this.axiosIns = axios.create();
			this.getArticles();
			
		},
		components: {
			quillEditor
		},
		methods: {

			getArticles() {
				this.memberId = JSON.parse(sessionStorage.getItem('member')).id;
				let params = {
					'memberId': this.memberId
				};
				this.$fetch('article/count',params).then((res) => {
					console.log(res.data);
					if (res.state == 1) {
						this.num = res.data;
						console.log(this.num);
						this.handleCurrentChange(this.currentPage);
					} else {
						alert("项目查询失败！");
					}
				});
			},
			transDate(dataList) {
				this.tableData = [];
				//this.tableData.push(dataList);
				dataList.forEach((item) =>{
						this.tableData.push(item);
				});
			},
			getFile(event) {
				console.log(event.target.files[0])
				this.file = event.target.files[0];
			},
			//每页下拉显示数据
			handleSizeChange: function(size) {
				this.pagesize = size;
				console.log("pagesize__" + this.pagesize);
			},
			//点击第几页
			handleCurrentChange: function(currentPage) {
				this.currentPage = currentPage;
				console.log(this.currentPage);
				this.loading = true;
				let params = {
					'pageNumber': this.currentPage,
					'pageCount': this.pagesize,
					'memberId':this.memberId
				};
				console.log(params);
				this.$fetch('/articles', params).then((res) => {
					this.loading = false;
					if (res.data) {
						if (res.state == 1) {
							console.log(res.data);
							this.transDate(res.data);
						} else {
							alert("项目查询失败！");
						}
					}
				});
			},
			handleAdd() {
				this.dialogTitle = '新增项目';
				this.isAddArticle = true;
				this.article = Object.assign({}, this.articleBackup)
				//this.articles = Object.assign({}, this.article)
				this.articleFormVisible = true
			},
			handleEdit(index, row) {
				this.isAddArticle = false;
				this.article = Object.assign({}, row)
				this.articleFormVisible = true
				this.rowIndex = index
			},
			editArticle(formName) {
				this.article.picture = this.file;
				this.article.isForMultiPart = true;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.isAddArticle)
						if (!this.isAddArticle) {
							this.article.memberId = this.memberId;
							this.tableData.splice(this.rowIndex, 1, this.article)
							
							console.log("文章修改");
							console.log(JSON.stringify(this.article));
							const formData = new FormData();
							Object.keys(this.article).forEach((key) => {
								console.log(key);
								if (this.article[key] != null) {
									formData.append(key, this.article[key]);
								}
							});
							let token = VueCookies.get('mb_token');
							this.axiosIns({
								method: "post",
								url: "article/modify",
								headers: {
									'Content-Type': 'multipart/form-data',
									"token": token
								},
								data: formData,
							}).then((res) => {
								console.log(res);
								if (res.data.state == 1) {
									this.articleFormVisible = false
									this.$message({
										message: '文章修改成功！',
										type: 'success'
									});
									this.handleCurrentChange(this.currentPage);
								} else {
									this.$message.error('文章修改失败！');
								}
							})
							
							
						} else {	
							console.log("新增项目提交");
							console.log(JSON.stringify(this.article));
							this.article.memberId = this.memberId;
							this.article.type = 0;
							this.article.istop = 0;
							const formData = new FormData();
							Object.keys(this.article).forEach((key) => {
								console.log(key);
								formData.append(key, this.article[key]);
							});
							let token = VueCookies.get('mb_token');
							this.axiosIns({
								method: "post",
								url: "/article",
								headers: {
									'Content-Type': 'multipart/form-data',
									"token": token
								},
								data: formData,
							}).then((res) => {
								console.log(res);
								if (res.data.state == 1) {
									this.articleFormVisible = false
									this.$message({
										message: '文章新增成功！',
										type: 'success'
									});
									this.handleCurrentChange(this.currentPage);
								} else {
									this.$message.error('文章新增失败！');
								}
							})

						}
					}
				})
			},
			handleDelete(index, row) {
				this.article = Object.assign({}, row);
				this.$confirm(`确定删除【${row.title}】`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log("id--" + this.article.id);
					let params = {
						'id': this.article.id
					};
					this.$post('article/delete', params).then((res) => {
						console.log(res)
						if (res.state == 1) {
							//this.tableData.splice(index, 1)
							this.$message({
							     message: '内容删除成功！',
							     type: 'success'
							});
							this.handleCurrentChange(this.currentPage);
						} else {
							this.$message.error("内容删除失败！");
						}
					});
				}).catch(() => {
					console.log('取消删除')
				})
			},
			resetForm(formName) {
				this.$refs[formName].clearValidate()
			},
			handTopChange(command) {
				console.log("istop--" + command.istop + "--id--" + command.row.id);
				let params = {
					'id': command.row.id,
					'istop': command.istop
				};
				this.$post('article/istop', params).then((res) => {
					console.log(res)
					if (res.state == 1) {
						this.$message({
							message: '项目置顶状态修改成功！',
							type: 'success'
						});
						this.handleCurrentChange(this.currentPage);
					} else {
						this.$message.error('项目置顶状态修改失败！');
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.article-box {
		width: 100%;

		.el-pagination {
			margin-top: 10px;
		}

		.quill-editor {
			height: 400px;
			margin-bottom: 50px;
		}
	}
</style>
