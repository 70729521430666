<template>
  <el-container class="index-box">
    <el-aside width="asideWidth">
      <div class="logo">
       <!-- <p v-show="!isCollapse" src="./../assets/logo.png" alt="ELementUI" width="122" height="27">募  邦</p> -->
<!-- 	   <el-dropdown trigger="click" class="user-name" @command="handleCommand">
	     <span class="el-dropdown-link">
	       {{ loginname }}<i class="el-icon-caret-bottom el-icon--right"></i>
	     </span>
	     <el-dropdown-menu slot="dropdown">
	       <el-dropdown-item command="profile" disabled>个人资料</el-dropdown-item>
	       <el-dropdown-item command="updatepwd" disabled>修改密码</el-dropdown-item>
	       <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
	     </el-dropdown-menu>
	   </el-dropdown> -->
      </div>
      <menus :isCollapse="isCollapse" />
    </el-aside>
    <el-container>
      <el-header>
        <headers />
      </el-header>
      <el-main>
        <router-view v-if="isRouterAlive" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import Menus from '../components/menus/menus'
  import Headers from '../components/header/header'
  import VueCookies from 'vue-cookies'
  export default {
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
	created() {
		console.log("reload_"+VueCookies.get('baseURL'))
		if(VueCookies.get('baseURL')) {
			this.$axios.defaults.baseURL = VueCookies.get('baseURL');
		}
	},
    components: {
      Menus,
      Headers
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
	  handleCommand (command) {
	    switch (command) {
	      case 'logout':
	        this.$router.replace({ path: '/login' })
	        break
	    }
	  }
    },
    computed: {
      isCollapse() {
        return this.$store.state.isCollapse
      },
      asideWidth() {
        return this.$store.state.isCollapse ? 'auto' : '200px'
      },
	  loginname () {
	    let { loginname } = JSON.parse(sessionStorage.getItem('user'))
	    return loginname.toUpperCase()
	  }
    },
	
  }
</script>

<style lang="scss">
  .index-box {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
      }
    }

    .el-aside {
      background-color: #20222A;
      height: 100vh;
      min-height: 100%;

      .logo {
        background-color: #20222A;
        color: rgba(255, 255, 255, .8);
        text-align: center;
        height: 60px;
        padding-top: 16px;
        font-size: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid gray;
      }
    }

    .el-header {
      border-bottom: 1px solid #f6f6f6;
      box-sizing: border-box;
      background-color: #fff;
      line-height: 60px;
    }
  }
</style>
