<template>
	<div class="article-box">
		<el-row>
			<el-col :span="24">
				<div class="tool-box">
					<el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新增用户</el-button>
				</div>
			</el-col>
		</el-row>
		<el-table :data="tableData" v-loading="loading" style="width:100%;margin-top: 20px;" >
			<el-table-column prop="id" label="ID" width="50">
			</el-table-column>
			<el-table-column prop="loginname" label="登陆账户" width="200">
			</el-table-column>
			<el-table-column prop="name" label="姓名" width="100">
			</el-table-column>
			<el-table-column prop="mobile" label="手机号" width="100">
			</el-table-column>
			<el-table-column prop="email" label="邮箱" width="200">
			</el-table-column>
			<el-table-column prop="type" label="类型" width="100">
				<template slot-scope="scope">
					{{['管理员','普通用户'][scope.row.type]}}
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态" width="100">
				<template slot-scope="scope">
					{{['停用','启用'][scope.row.status]}}
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="简介" width="100">
			</el-table-column>
			<el-table-column prop="createtime" label="创建时间"  :formatter="dateFormat">
			</el-table-column>
			<el-table-column label="操作" width="300">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
					<el-button size="mini" type="primary" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
		 :page-sizes="[5, 10, 20, 40]" :page-size="pagesize" layout="total, sizes,prev, pager, next" :total="num" prev-text="上一页"
		 next-text="下一页">
		</el-pagination>
		<el-dialog :title="dialogTitle" :visible.sync="mpsFormVisible" :close-on-click-modal=false top="10vh" @close="resetForm('mpsForm')">
			<el-form :model="mps" ref="mpsForm" :rules="rules">
				<el-form-item label="登陆账户名" prop="loginName" label-width="100px">
					<el-input v-model="mps.loginName" placeholder="请输入登陆账户名" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="mpName" label-width="100px">
					<el-input v-model="mps.name" placeholder="请输入名称" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile" label-width="100px">
					<el-input v-model="mps.mobile" placeholder="请输入手机号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="邮箱号" prop="email" label-width="100px">
					<el-input v-model="mps.email" placeholder="请输入邮箱号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password" label-width="100px">
					<el-input v-model="mps.password" placeholder="请输入密码" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="用户类型" prop="type" label-width="100px">
					<el-radio-group v-model="mps.type">
						<el-radio-button label="0" value="0">管理员</el-radio-button>
						<el-radio-button label="1" value="1">普通用户</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="用户状态" prop="status" label-width="100px">
					<el-radio-group v-model="mps.status">
						<el-radio-button label="0" value="0">停用</el-radio-button>
						<el-radio-button label="1" value="1">启用</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="简介" label-width="100px">
					<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5}"  size="medium" placeholder="请输入内容" v-model="mps.remark">
					</el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: 1.25rem;">
				<el-button @click="mpsFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="editMp('mpsForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios' //引入
	import VueCookies from 'vue-cookies'
	export default {
		data() {
			return {
				dialogTitle: '编辑内容 ',
				currentPage: 1, //默认显示页面为1
				pagesize: 10, //    每页的数据条数
				mpsFormVisible: false,
				rowIndex: 9999,
				tableData:[],
				loading: true,
				isAddMps: false,
				memberId:'',
				mps: {
					id:'',
					loginName: '',
					name: '',
					email:'',
					mobile: '',
					password: '',
					type: '',
					status: '',
				},
				num: 100,
				rules: {
						loginName: [
							{ required: true, message: '请输入姓名', trigger: 'blur' }
						],
						name: [
							{ required: true, message: '请输入姓名', trigger: 'blur' }
						],
						mobile: [
							{ required: true, message: '请输入手机号', trigger: 'blur' }
						],
						email: [
							{ required: true, message: '请输入邮箱', trigger: 'blur' }
						],
						password: [
							{ required: true, message: '请输入密码', trigger: 'blur' }
						]
						
				},
				mpsBackup: Object.assign({}, this.mps),
			}
		},
		mounted() {
			this.axiosIns = axios.create();
			this.getMps();
			
		},
		methods: {
			getMps() {
				this.$fetch('member/member/count').then((res) => {
					console.log(res.data);
					if (res.state == 1) {
						this.num = res.data;
						console.log(this.num);
						this.handleCurrentChange(this.currentPage);
					} else {
						alert("用户查询失败！");
					}
				});
			},
			getFile(event) {
				console.log(event.target.files[0])
				this.file = event.target.files[0];
			},
			//每页下拉显示数据
			handleSizeChange: function(size) {
				this.pagesize = size;
				console.log("pagesize__" + this.pagesize);
			},
			//点击第几页
			handleCurrentChange: function(currentPage) {
				this.currentPage = currentPage;
				console.log(this.currentPage);
				this.loading = true;
				let params = {
					'pageNumber': this.currentPage,
					'pageCount': this.pagesize,
				};
				var that = this;
				console.log(params);
				this.$fetch('member/members', params).then((res) => {
					this.loading = false;
					if (res.data) {
						if (res.state == 1) {
							console.log(res.data);
							that.tableData = res.data
						} else {
							alert("用户列表查询失败！");
						}
					}
				});
			},
			handleAdd() {
				this.dialogTitle = '新增用户';
				this.isAddMps = true;
				this.mps = Object.assign({}, this.mpsBackup)
				//this.articles = Object.assign({}, this.article)
				this.mpsFormVisible = true
			},
			handleEdit(index, row) {
				this.isAddMps = false;
				this.mps = Object.assign({}, row)
				this.mpsFormVisible = true
				this.rowIndex = index
			},
			editMp(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.isAddMps)
						if (!this.isAddMps) {
							
							this.tableData.splice(this.rowIndex, 1, this.mps)
							
							console.log("用户修改");
							console.log(JSON.stringify(this.mps));
							const formData = new FormData();
							Object.keys(this.mps).forEach((key) => {
								console.log(key);
								if (this.mps[key] != null) {
									formData.append(key, this.mps[key]);
								}
							});
							let token = VueCookies.get('mb_token');
							this.axiosIns({
								method: "post",
								url: "/member/updatemember",
								headers: {
									'Content-Type': 'multipart/form-data',
									"token": token
								},
								data: formData,
							}).then((res) => {
								console.log(res);
								if (res.data.state == 1) {
									this.mpsFormVisible = false
									this.$message({
									     message: '修改成功！',
									     type: 'success'
									});
									this.handleCurrentChange(this.currentPage);
								} else {
									this.$message.error('修改失败！');
								}
							})
							
							
						} else {	
							console.log("新增用户提交");
							console.log(JSON.stringify(this.mps));
							const formData = new FormData();
							Object.keys(this.mps).forEach((key) => {
								console.log(key);
								formData.append(key, this.mps[key]);
							});
							let token = VueCookies.get('mb_token');
							this.axiosIns({
								method: "post",
								url: "/member/addmember",
								headers: {
									'Content-Type': 'multipart/form-data',
									"token": token
								},
								data: formData,
							}).then((res) => {
								console.log(res);
								if (res.data.state == 1) {
									this.mpsFormVisible = false
									this.$message({
										message: '用户新增成功！',
										type: 'success'
									});
									this.handleCurrentChange(this.currentPage);
								} else {
									this.$message.error('用户新增失败！');
								}
							})

						}
					}
				})
			},
			handleDelete(index, row) {
				this.mps = Object.assign({}, row);
				this.$confirm(`确定删除【${row.title}】`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log("id--" + this.mps.id);
					let params = {
						'id': this.mps.id
					};
					this.$post('/member/delete', params).then((res) => {
						console.log(res)
						if (res.state == 1) {
							//this.tableData.splice(index, 1)
							this.$message({
							     message: '用户删除成功！',
							     type: 'success'
							});
							this.handleCurrentChange(this.currentPage);
						} else {
							this.$message.error("删除失败！");
						}
					});
				}).catch(() => {
					console.log('取消删除')
				})
			},
			dateFormat(row, column, cellValue, index) {
				let date = new Date(row.createtime);
				let Y = date.getFullYear() + '-';
				let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
				let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
				let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
				let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
				let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return Y + M + D + h + m + s;
			},
			resetForm(formName) {
				this.$refs[formName].clearValidate()
			},
		}
	}
</script>

<style lang="scss" scoped>
	.article-box {
		width: 100%;

		.el-pagination {
			margin-top: 10px;
		}

		.quill-editor {
			height: 400px;
			margin-bottom: 50px;
		}
	}
</style>
